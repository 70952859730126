/// <reference path="o365.pwa.declaration.sw.apiRequestOptions.ApiPwaOfflineSyncRequestOptions.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { Request } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';

import type * as ApiPwaOfflineSyncOptionsModule from 'o365.pwa.declaration.sw.apiRequestOptions.ApiPwaOfflineSyncRequestOptions.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const { ApiRequestOptions } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.apiRequestOptions.ApiRequestOptions.d.ts')>("o365.pwa.modules.sw.apiRequestOptions.ApiRequestOptions.ts");

    class ApiPwaOfflineSyncOptions implements ApiPwaOfflineSyncOptionsModule.ApiPwaOfflineSyncOptions {
        readonly requestGuid: string;
        readonly appId: string;
        readonly dataObjectId: string;
        readonly appIdOverride?: string;
        readonly databaseIdOverride?: string;
        readonly objectStoreIdOverride?: string;
        readonly personID?: number;
        readonly deviceRef?: string;
        readonly isFileTable: boolean;
        readonly truncateOptions: ApiPwaOfflineSyncOptionsModule.IApiPwaOfflineSyncTruncateOptions;
        readonly generateOfflineDataOptions: ApiPwaOfflineSyncOptionsModule.IApiPwaOfflineSyncGenerateOfflineDataOptions;
        readonly rowCountOptions: ApiPwaOfflineSyncOptionsModule.IApiPwaOfflineSyncRowCountOptions;
        readonly retrieveOptions: ApiPwaOfflineSyncOptionsModule.IApiPwaOfflineSyncRetrieveOptions;

        constructor(options: ApiPwaOfflineSyncOptionsModule.IApiPwaOfflineSyncOptions) {
            this.requestGuid = options.requestGuid;
            this.appId = options.appId;
            this.dataObjectId = options.dataObjectId;
            this.appIdOverride = options.appIdOverride;
            this.databaseIdOverride = options.databaseIdOverride;
            this.objectStoreIdOverride = options.objectStoreIdOverride;
            this.personID = options.personID;
            this.deviceRef = options.deviceRef;
            this.isFileTable = options.isFileTable;
            this.truncateOptions = options.truncateOptions;
            this.generateOfflineDataOptions = options.generateOfflineDataOptions;
            this.rowCountOptions = options.rowCountOptions;
            this.retrieveOptions = options.retrieveOptions;
        }

        static async fromRequest(request: Request): Promise<InstanceType<typeof ApiRequestOptions<ApiPwaOfflineSyncOptions>>> {
            const clonedRequest = request.clone();
            const body = await clonedRequest.json();

            return new ApiRequestOptions(request, new ApiPwaOfflineSyncOptions(body));
        }
    }

    self.o365.exportScripts<typeof import('o365.pwa.declaration.sw.apiRequestOptions.ApiPwaOfflineSyncRequestOptions.d.ts')>({ ApiPwaOfflineSyncOptions });
})();
